import React from "react";
import Helmet from "react-helmet";
import Header from "../components/layouts/header";
import css from "@emotion/css";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import { PrimaryLink } from "../components/PrimaryButton";
import BackgroundImage from "gatsby-background-image";
import { HeroBanner } from "../components/HeroBanner";
function Index({ data }) {
  const {
    contentfulInvestorsPage: {
      pageTitle,
      heroBanner: { title, heroImage },

      topContent,
      starBanner,
      starBannerText,
      investorBanners
    }
  } = data;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="CHANGE-ME" />
        <meta
          name="keywords"
          content="Ratio, fintech, finance, software, invest, investment opportunities, invest in Ratio, invest in us, investors"
        />
      </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py">
            <HeroBanner version="left" heroImage={heroImage} title={title} />
          </div>
        </div>
      </div>
      <section className="section-py">
        {" "}
        <div className="container">
          {" "}
          <TextSection
            aCSS={`
              padding-top: 60px;
              padding-bottom: 60px;

        `}
            html={topContent.md.html.replace(":", ": <br/>")}
          />
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              {" "}
              <PrimaryLink to="/contact/" text="Get in touch" />
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage
        fluid={[
          "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2))",
          starBanner.fluid
        ]}
      >
        <div
          className="container"
          css={css`
            text-align: center;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100vh;
          `}
        >
          <span />
          <h2
            css={css`
              font-size: 38px;
              font-weight: 500;
              letter-spacing: -1px;
              line-height: 1.2;

              @media (min-width: 576px) {
                font-size: 48px;
              }
              @media (min-width: 768px) {
                font-size: 64px;
              }
            `}
          >
            {starBannerText}
          </h2>

          <div>
            {" "}
            <button
              onClick={e => {
                typeof window !== "undefined" &&
                  document
                    .getElementById("hook")
                    .scrollIntoView({ behavior: "smooth" });
              }}
              className="btn btn-outline-light"
              css={css`
                border: 4px solid #ffffff;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                padding: 1em 3em;
                &:hover {
                  color: var(--primary);
                }
                @media (min-width: 576px) {
                  font-size: 18px;
                }
                @media (min-width: 768px) {
                  font-size: 22px;
                }
              `}
            >
              Begin
            </button>
          </div>
          <span />
        </div>
      </BackgroundImage>
      <div id="hook" />
      {investorBanners.map((banner, i) => (
        <section
          key={i}
          css={css`
            background: ${i % 2 !== 1 ? "#E7EAEA" : "#fff"};

            h2,
            h3 {
              font-family: "futura-pt", sans-serif;
              font-size: 38px;
              font-weight: 500;
              line-height: 48px;
            }
            h2 {
              color: var(--primary);
              margin-bottom: 2rem;
            }
          `}
        >
          <div className="container ">
            <div className="row section-py">
              {i === 0 && (
                <>
                  <div
                    className={`col-12 col-md-5 offset-md-1  `}
                    dangerouslySetInnerHTML={{ __html: banner.content.md.html }}
                  />
                  <div className={`col-12 col-md-5 offset-md-1`}>
                    <GatsbyImage fluid={banner.image.fluid} />
                  </div>
                </>
              )}
              {i === 1 && (
                <>
                  <div className={`col-12 col-md-5 order-2 order-md-0`}>
                    <GatsbyImage fluid={banner.image.fluid} />
                  </div>
                  <div
                    className={`col-12 col-md-6 offset-md-1  `}
                    dangerouslySetInnerHTML={{ __html: banner.content.md.html }}
                  />
                </>
              )}
              {i === 2 && (
                <>
                  <div
                    className={`col-12 col-md-6   `}
                    dangerouslySetInnerHTML={{ __html: banner.content.md.html }}
                  />
                  <div className={`col-12 col-md-6 `}>
                    <GatsbyImage fluid={banner.image.fluid} />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      ))}
    </>
  );
}

export default Index;

export const query = graphql`
  {
    contentfulInvestorsPage {
      pageTitle: title
      heroBanner {
        title
        subtitle
        heroImage {
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
      starBannerText
      starBanner {
        title
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyContentfulFluid
        }
      }
      topContent {
        md: childMarkdownRemark {
          html
        }
      }
      investorBanners {
        image {
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        content {
          md: childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const TextSection = ({ html, aCSS }) => (
  <div className="row">
    <div className="col-12 col-md-10   offset-0 offset-md-1">
      <div
        id="aboutContentTop"
        css={css`
          h1,
          h2 {
            font-family: "futura-pt", sans-serif;
            font-weight: 600;

            margin-bottom: 2rem;
            color: var(--primary);

            @media (min-width: 992px) {
              max-width: 60%;
            }
          }
          h2 {
            font-size: 36px;
          }
          h3 {
            font-size: 28px;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    </div>
  </div>
);
